import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleHeader from "../components/ArticleHeader"
import FullscreenImage from "../components/FullscreenImage"
import FullscreenVideo from "../components/FullscreenVideo"
import TextBlock from "../components/TextBlock"

// Styling
import { fonts } from "../styles/fonts"
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import content from "../../static/content"

const ArticlePage = ({ location }: any) => {
  if (
    location !== undefined &&
    location !== null &&
    location.search !== undefined &&
    location.search !== null
  ) {
    const pathTitle = location.search
      .replace("?", "")
      .replace(/-/g, " ")
      .replace(/_/g, "-")

    console.log(pathTitle)

    const article = content.homepage.articles.cases.find(
      ({ title }) => title.toLowerCase() === pathTitle.toLowerCase()
    )

    if (article === undefined || article === null) {
      return (
        <Layout>
          <SEO title="No article found" />

          <AlertWrapper>
            <AlertHeader>No article selected</AlertHeader>

            <Alert>
              Please select a case / article on the{" "}
              <StyledLink to={"/"}>Homepage.</StyledLink>
            </Alert>
          </AlertWrapper>
        </Layout>
      )
    } else {
      return (
        <Layout>
          <SEO title={article.company} />
          <ArticleHeader {...article} />

          {article.image.large !== "" && <FullscreenImage {...article.image} />}

          {article.video.source !== "" && (
            <FullscreenVideo {...article.video} />
          )}

          <TextBlock text={article.story} />
        </Layout>
      )
    }
  }
}

export default ArticlePage

const AlertWrapper = styled.div`
  padding: 25px 5%;
  font-family: ${fonts.primary};
  line-height: 1.2;
  text-align: center;
  margin-bottom: 5px;
`

const AlertHeader = styled.h2`
  font-size: 25px;
  letter-spacing: 2px;
  text-transform: capitalize;

  ${mq.from.S`
    padding: 25px 15%;
    font-size: 40px;
  `}
`

const Alert = styled.h3`
  font-size: 20px;
  letter-spacing: 1px;

  ${mq.from.S`
    padding: 25px 15%;
    font-size: 35px;
  `}
`

const StyledLink = styled(Link)`
  color: ${colors.white};
  transition: 0.5s;
  text-decoration: underline;

  &:hover {
    color: ${colors.accent1};
  }
`
