import React from "react"
import styled from "styled-components"

// Styling
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"
import { fonts } from "../styles/fonts"

// Types
import { ICase } from "./BlogsBlock"

// Components
import Container from "./layout/Container"
import { Row, Col } from "./layout/Grid"
import Divider from "./layout/Divider"

const ArticleHeader = (article: ICase) => (
  <Header>
    <Container>
      <Row>
        {article.date && (
          <PublicationDate>
            <div>&mdash; {article.date}</div>
          </PublicationDate>
        )}

        <Col m={8} mOffset={2}>
          {article.title && article.title.length && (
            <Title>{article.title}</Title>
          )}

          {article.slogan && (
            <Headline>
              <div>{article.slogan}</div>
            </Headline>
          )}
        </Col>

        <Col m={3} mOffset={1}>
          {article.type && article.author && (
            <PublicationMetadata>
              <div>
                &mdash; {article.type}
                <br />
                By {article.author}
              </div>
            </PublicationMetadata>
          )}
        </Col>
      </Row>

      <Row>
        <Col m={14} mOffset={2}>
          <div>
            <Divider color={colors.white} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col m={9} mOffset={3}>
          <div>
            <Intro>{article.intro}</Intro>
          </div>
        </Col>
      </Row>
    </Container>
  </Header>
)

export default ArticleHeader

// Header
const Header = styled.header`
  position: relative;
  margin-bottom: 50px;
  font-family: ${fonts.primary};
`

const Intro = styled.h4`
  line-height: 1.5;
  margin-top: 25px;
`

const PublicationDate = styled.div`
  margin-bottom: 8px;

  ${mq.from.M`
    position: absolute;
    top: 8px;
    width: 25vh;
    margin-bottom: 0;
    text-align: right;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
  `}

  ${mq.from.XXL`
    font-size: 30px;
    line-height: 1;
  `}
`

const Title = styled.div`
  ${textStyles.headingL};
  font-size: 3em;
  margin-bottom: 30px;
`

const Headline = styled.h3`
  ${textStyles.headingS};
  margin-bottom: 30px;
  color: ${colors.accent1};
`

const PublicationMetadata = styled.div`
  margin-bottom: 16px;

  ${mq.from.M`
    margin-bottom: 0;
  `}

  ${mq.from.XXL`
    font-size: 30px;
    line-height: 1;
  `}
`
