import React from "react"
import styled from "styled-components"

// Components
import { FullWrapper } from "./InfoFooter"

interface IImage {
  large: string
  small: string
  alt: string
}

const FullscreenImage = (image: IImage) => (
  <FullWrapper>
    <Image src={image.large} alt={image.alt} />
  </FullWrapper>
)
export default FullscreenImage

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
`
