import React from "react"
import styled from "styled-components"

// Components
import { Wrapper } from "./TipsBlock"

// Styling
import { mq } from "../styles/utils/mq"
import { fonts } from "../styles/fonts"
import colors from "../styles/colors"

interface IText {
  text: string
}

const TextBlock = ({ text }: IText) => (
  <Wrapper>
    <Text
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  </Wrapper>
)
export default TextBlock

const Text = styled.p`
  padding: 25px 5%;
  font-family: ${fonts.primary};
  font-size: 22px;
  margin-bottom: 0;
  line-height: 1.2;
  letter-spacing: 1px;

  ${mq.from.S`
    padding: 25px 15%;
  `}

  a {
    color: ${colors.accent1};
    transition: 0.5s;

    &:hover {
      color: ${colors.white};
      text-decoration: underline;
    }
  }

  video {
    margin-top: 25px;
    outline: none;
  }
`
