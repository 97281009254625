import React from "react"
import styled from "styled-components"

// Components
import { FullWrapper } from "./InfoFooter"

interface IVideo {
  source: string
}

const FullscreenVideo = (video: IVideo) => (
  <FullWrapper>
    <Video width="100%" height="auto" controls>
      <source src={video.source} type="video/mp4" />
      Your browser does not support the video tag.
    </Video>
  </FullWrapper>
)

export default FullscreenVideo

const Video = styled.video`
  width: 100%;
  height: auto;
  outline: none;
`
